import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { AlertController, IonSlides, LoadingController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { LoginFormPage } from '../auth/login-form/login-form.page';
import { GuestUserService } from '../services/guest-user.service';
import { VerificationModalPage } from '../components/verification-modal/verification-modal.page';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
})
export class SignUpPage implements OnInit {

  img = '../../../assets/login-background.jpg';

  @Input() signUpDetails: any;
  firstname = '';
  lastname = '';
  phone = '';
  email = '';
  password = '';
  confirm_password = '';

  constructor(
    private auth: AuthService,
    private keyboard: Keyboard,
    private loadingCtrl: LoadingController,
    private router: Router,
    private utils: UtilsService,
    private modalCtrl: ModalController,
    private guestUserService: GuestUserService,
    private alertCtrl: AlertController,
    private storage: Storage,
  ) { }

  ngOnInit() {
    if (this.signUpDetails) {
      this.populateFields();
    }
  }

  populateFields() {
    const nameSplit = this.signUpDetails.name.split(' ');
    this.firstname = nameSplit[0];
    this.lastname = nameSplit[1];
    this.phone = this.signUpDetails.phone;
    this.email = this.signUpDetails.email;
  }

  async onSubmit(form: NgForm) {
    if (form.valid) {
      const {firstname, lastname, phone, email, password, confirm_password} = form.value;
      const loading = await this.loadingCtrl.create({
        message: 'Please wait...',
        translucent: true
      });
      loading.present();

      this.auth.signUp(firstname, lastname, phone, email, password, confirm_password)
      .subscribe(resp => {
        loading.dismiss();
        this.utils.showSuccessMessage(resp.message[0]);

        if (resp.message[0] === 'Error in the password field - Passwords must match.'){
          form.controls.password.reset();
          form.controls.confirm_password.reset();
        }

        if (resp.message[0] === 'Phone number or email already in use') {
          form.controls.email.reset();
          form.controls.phone.reset();
        }

        if (resp.code === 21) {
          this.dismiss();
          this.verifyEmail(resp.data.user.id);
          this.auth.setRespUserId(resp.data.user.id);
          this.storage.set('credentials', {phone, password});
          form.resetForm();
        }
      },
      err => {
        loading.dismiss();
        this.utils.showErrorMessage('We cannot process your request', 'Server Down', 5000);
      });

    }
  }

  onEnter(event: any, form: NgForm) {
    if (event.keyCode === 13) {
      if (this.keyboard.isVisible) {
        if (form.valid) {
          this.onSubmit(form);
        }
        this.keyboard.hide();
      }
    }
  }

  dismiss() {
    return this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  async goToLogIn() {
    await this.dismiss();
    const modal = await this.modalCtrl.create({
      component: LoginFormPage,
      cssClass: 'login-modal'
    });
    return await modal.present();
  }

  /**
   * Presents an alert to collect the user's verification code to verify their email.
   */
  async verifyEmail(userId: number) {
    const modal = await this.modalCtrl.create({
      component: VerificationModalPage,
      cssClass: 'verification-modal',
      backdropDismiss: false,
      componentProps: {
        requestNewCode: (id) => {  this.auth.requestNewCode(id); },
        verifyEmail: (id, code) => { this.auth.verifyEmail(id, code, 'SignUp'); }, 
        userId
      }
    });

    return modal.present();
  }

}
