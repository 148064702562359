import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import {
  ModalController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { CartService } from '../../services/cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { OrderResponsePage } from '../../order-response/order-response.page';
import { NgForm, NgModel } from '@angular/forms';
import { ApiResponse } from './../../models/takeout.model';
import {
  Merchant,
  OrderOption,
  PaymentMethod,
} from 'src/app/models/merchant.model';
import { UtilsService } from 'src/app/services/utils.service';
import { SubSink } from 'subsink';
import { LoginFormPage } from 'src/app/auth/login-form/login-form.page';
import { MapPage } from 'src/app/components/map/map.page';
import { MatStepper } from '@angular/material/stepper';
import { CartItem } from 'src/app/models/cart.model';
import { MerchantDetailsService } from 'src/app/services/merchant-details.service';
import {
  DeliveryAddress,
  BillingAddress,
  PaymentCard,
  User,
} from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { DeliveryProviderPage } from 'src/app/components/delivery-provider/delivery-provider.page';
import { BillingAddressPage } from 'src/app/components/billing-address/billing-address.page';
import { GuestUserService } from 'src/app/services/guest-user.service';
import { SignUpPage } from 'src/app/sign-up/sign-up.page';
import { Location } from '@angular/common';
import { FeedService } from 'src/app/services/feed.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentPage } from 'src/app/components/payment/payment.page';
import { delay, repeatWhen, retryWhen, tap } from 'rxjs/operators';
import { AlertInput } from '@ionic/core';
import { TakeoutPartnerService } from 'src/app/services/takeout-partner.service';
import { Partner, Transaction } from 'src/app/models/partner.model';
import { Receipt } from 'src/app/models/order.model';
import { jsPDF } from 'jspdf';
import { PaymentStatus } from 'src/app/models/payment.model';

@Component({
  selector: 'app-pre-checkout',
  templateUrl: './pre-checkout.page.html',
  styleUrls: ['./pre-checkout.page.scss'],
})
export class PreCheckoutPage implements OnInit, DoCheck, OnDestroy {
  contact_name = '';
  contact_number = '';
  contact_email = '';
  delivery_address: DeliveryAddress;
  paymentMethod;
  forDelivery = false;
  firstName: string;
  nameOnCard: string;
  cardNumber: string;
  cardType: string = '';
  expiryDate: string;
  cvc: string;
  guestDeliveryAddress: DeliveryAddress;
  returnedAddress: DeliveryAddress;
  selectedAddress: DeliveryAddress;
  private subs = new SubSink();
  cartCount = 0;
  cartItem: CartItem[];
  total = 0;
  paymentMethods: PaymentMethod[];
  deliveryMethods: OrderOption[];
  selectedPaymentMethod;
  addresses: DeliveryAddress[] = [];
  firstAddress: DeliveryAddress;
  loadingAddresses = false;
  successIcon = '../../../assets/icon/success-icon.png';
  unsuccessfulIcon = '../../../assets/icon/unsuccessful-icon.png';
  addrIsSimilar = false;
  orderCode = '';
  orderTotal: number;
  wasClicked = false;
  transactionLoading = false;
  frstIsEditable = true;
  secIsEditable = true;
  // Here we initialize the values of billingAddress and cardDetails
  billingAddress: BillingAddress = {
    line1: '',
    line2: '',
    city: '',
    stateOrParish: '',
    country: '',
    postalCode: '',
  };
  // tslint:disable-next-line: max-line-length
  cardDetails: PaymentCard = {
    nameOnCard: '',
    cardNumber: '',
    cvv: '',
    expiryDate: '',
    billingAddress: this.billingAddress,
    type: '',
    enabled: true,
  };
  merchant: Merchant;
  newAddress: DeliveryAddress;
  newAddressWasAdded = false;
  addingNewCard = false;
  paymentCards = [];
  selectedPaymentCard = null;
  options = [];
  checker = null;
  cardTypeImage = null;
  xpMonth = null;
  xpYear = null;
  cardCVV = null;
  shouldSaveCard = true;
  cardIsValid = true;
  newCardAdded = false;
  recentlyAddedCard: string;
  partners: Partner[];
  selectedPartner: string;
  empId: string;
  pin: string;
  receipt: Receipt;

  constructor(
    private modalCtrl: ModalController,
    private cartService: CartService,
    private authService: AuthService,
    private merchantDetailsService: MerchantDetailsService,
    private util: UtilsService,
    private alertCtrl: AlertController,
    private secondaryAlertCtrl: AlertController,
    private userService: UserService,
    private guestUserService: GuestUserService,
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private router: Router,
    private location: Location,
    private feedService: FeedService,
    private paymentService: PaymentService,
    private partnerService: TakeoutPartnerService
  ) {}

  ngOnInit() {
    this.firstName = this.authService.currentUser.firstname;
    if (!this.authService.isGuest) {
      this.populateFields(this.authService.currentUser);
    }

    this.subs.sink = this.cartService.getPaymentMethods().subscribe(
      resp => {
        this.paymentMethods = resp.data.payment_methods as PaymentMethod[];
      },
      error => this.util.showServerError()
    );

    this.fetchPaymentCard();
    this.fetchAddresses();
    this.fetchTakeoutPartners();

    if (this.merchant === undefined) {
      this.feedService.getMerchants().subscribe(
        resp => {
          const merchants = resp.data.merchants as Merchant[];
          const merchant = merchants.find(
            merchant => merchant.id === this.cartItem[0].merchant_id
          );
          this.merchant = merchant;
          this.cartService.setMerchant(merchant);
        },
        error => this.util.showServerError(),
        () => {
          this.cartService
            .getMerchantDeliveryMethods(this.cartItem[0].merchant_id)
            .subscribe(
              resp => {
                this.deliveryMethods = resp.data
                  .delivery_methods as OrderOption[];
                this.deliveryMethods.forEach(async dm => {
                  if (dm.label === 'Delivery') {
                    this.cartService.setMerchant(this.merchant);
                    const alert = await this.alertCtrl.create({
                      header: 'We Deliver!',
                      message: this.merchant.delivery_details,
                      buttons: ['Okay'],
                    });
                    alert.present();
                  }
                });
              },
              error => this.util.showServerError()
            );
        }
      );
    }

    if (this.newAddressWasAdded) {
      this.selectedAddress = this.returnedAddress;
    }

    this.cartCount = this.cartService.getQuantity();
    this.cartItem = this.cartService.getItems();
    this.total = this.cartService.getTotal();

    this.userService.refreshNeeded.subscribe(() => {
      this.fetchAddresses();
    });
  }

  ngDoCheck() {
    this.cartCount = this.cartService.getQuantity();
    this.cartItem = this.cartService.getItems();
    this.total = this.cartService.getTotal();
    // this.fetchMerchantDetails();
    // Here we check if the username is not undefined and the currentUser object exists
    if (this.firstName !== undefined && this.authService.currentUser) {
      // If the condition above is satisfied we check if the currentUser firstname is not equal to guest
      // and the currentUser firstname is different from what is was before
      if (
        this.authService.currentUser.firstname !== 'Guest' &&
        this.authService.currentUser.firstname !== this.firstName
      ) {
        // If this condition is satisfied we update the value of userName
        // these conditions ensure that this code is executed only when neccessary
        this.firstName = this.authService.currentUser.firstname;
        this.populateFields(this.authService.currentUser);
      }
    }
  }

  sortOptions() {
    if (this.options) {
      if (this.options[0].billingAddress) {
        this.options.reverse();
      }
    }
  }

  fetchMerchantDetails() {
    if (this.merchant === undefined) {
      this.feedService.getMerchants().subscribe(
        resp => {
          const merchants = resp.data.merchants as Merchant[];
          this.merchant = merchants.find(
            merchant => merchant.id === this.cartItem[0].merchant_id
          );
        },
        error => this.util.showServerError()
      );
    }

    if (this.newAddressWasAdded) {
      this.selectedAddress = this.returnedAddress;
    }
  }

  populateFields(user: User) {
    this.contact_name = `${user.firstname} ${user.lastname}`;
    this.contact_number = user.phone;
    this.contact_email = user.email;
  }

  updateBillingAddress(box) {
    if (!this.delivery_address && box.checked && this.addresses.length == 0) {
      this.presentBillingAddressAlert();
    }

    if (this.addresses.length > 0 && box.checked) {
      this.presentUserAddressesAlert();
    }

    // if (this.delivery_address && this.addrIsSimilar === true && (this.addresses.length == 1)) {
    //   // Assign Delivery Address property to the appropriate Billing Address property
    //   this.billingAddress.line1 = this.delivery_address.line1;
    //   this.billingAddress.line2 = this.delivery_address.line2;
    //   this.billingAddress.city = this.delivery_address.city;
    //   this.billingAddress.stateOrParish = this.delivery_address.parish;
    //   this.billingAddress.country = this.delivery_address.country;
    //   this.billingAddress.postalCode = '';
    //   this.billingAddress.phoneNumber = this.contact_number;
    // }

    if (this.addrIsSimilar === false) {
      // Here we reset the Billing Address Object to empty
      this.billingAddress = {
        line1: '',
        line2: '',
        city: '',
        stateOrParish: '',
        country: '',
        postalCode: ''
      };
    }
  }

  async changeBillingAddress() {
    const modal = await this.modalController.create({
      component: BillingAddressPage,
      cssClass: 'address-modal-2',
      componentProps: {
        address: this.billingAddress,
      },
    });

    modal.onDidDismiss().then(resp => {
      // Here we replace the previous address with the new one from the form
      if (resp.data !== undefined) {
        this.billingAddress = resp.data.returnedAddress;
      }
    });
    return modal.present();
  }

  updateDelivery(e) {
    const { value } = e.target;
    this.forDelivery = value === 'Delivery';
    if (this.forDelivery && !this.authService.isGuest) {
      this.fetchAddresses();
    }
  }

  updatePaymentOption(e) {
    const { value } = e.target;
    this.paymentMethod = value;
    this.selectedPaymentMethod = value;
  }

  updateSelectedPaymentCard(e) {
    const { value } = e.target;
    this.selectedPaymentCard = value;
    this.cardTypeImage = `../../../assets/card-types/${value.type}.png`;
    const dateArr = value.expiryDate.split('-');
    this.xpMonth = dateArr[0];
    this.xpYear = dateArr[1];
  }

  resetSelectedMethod() {
    this.selectedPaymentMethod = undefined;
    this.paymentMethod = undefined;
    this.selectedPaymentCard = null;
    this.addingNewCard = false;
  }

  validateExpiryDate(e) {
    const {value} = e.target;
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    if (value.length === 7) {
      // tslint:disable-next-line: radix
      if (value.substring(3, 7) == year && (parseInt(value.substring(0, 2)) <= month)) {
        this.cardIsValid = false;
      }
      // tslint:disable-next-line: radix
      if (value.substring(3, 7) < year) {
        this.cardIsValid = false;
      }
    } else {
      this.cardIsValid = true;
    }

    if (this.expiryDate.length === 2) {
      this.expiryDate += '-';
    }
  }

  formatExpiryDate(date: string){
    const [month, year] = date.split('-');
    return `${year}-${month}`
  }

  async addDeliveryAddress(isDelivery: boolean) {
    const modal = await this.modalController.create({
      component: MapPage,
      cssClass: 'address-modal',
      componentProps: {
        isDelivery
      }
    });

    modal.onDidDismiss().then(resp => {
      // Here we get the Guest Address from the Map Modal
      if (resp.data !== undefined) {
        console.log(resp);
        this.newAddressWasAdded = true;
        this.guestDeliveryAddress = resp.data.guestDeliveryAddress;
        this.returnedAddress = resp.data.returnedAddress;
        this.fetchAddresses();
      }
    });
    return modal.present();
  }

  async addDeliveryProvider() {
    // If user is guest they will be prompted to create an account
    if (this.authService.isGuest) {
      const modal = await this.modalController.create({
        component: DeliveryProviderPage,
        cssClass: 'address-modal',
      });
      return modal.present();
    } else {
      const modal = await this.modalController.create({
        component: DeliveryProviderPage,
        cssClass: 'address-modal',
      });
      return modal.present();
    }
  }

  safeMaskedNumber(cardNumber: string) {
    const last4 = cardNumber.substring(cardNumber.length - 4);
    const mask = cardNumber
      .substring(0, cardNumber.length - 4)
      .replace(/\d/g, '*');

    return mask + last4;
  }

  onChangeHandler($event) {
    this.delivery_address = $event.target.value;
  }

  shouldSave() {
    if (this.shouldSaveCard === true) {
      this.shouldSaveCard = false;
    } else {
      this.shouldSaveCard = true;
    }

    console.log(this.shouldSaveCard);
  }

  async submitOrder(form1: NgForm, form2: NgForm, stepper: MatStepper) {
    this.transactionLoading = true;
    const {
      name,
      phone,
      email,
      delivery_method,
      delivery_address,
    } = form1.value;
    // tslint:disable-next-line: variable-name
    const payment_method = this.paymentMethod; // Set payment method

    if (this.selectedPaymentMethod === 'Credit/Debit Card') {
      const orderTotal = this.cartService.getTotal();
      const cardPaymentMethod = this.paymentMethods.find(
        m => m.label === 'Credit/Debit Card'
      );
      const limits = cardPaymentMethod.limit.split('-');

      // tslint:disable-next-line: radix
      if (orderTotal < parseInt(limits[0])) {
        // tslint:disable-next-line: radix
        this.util.showNeutralMessage(
          `Minimum transaction amount $${parseInt(limits[0])}.`
        );
        return;
        // tslint:disable-next-line: radix
      } else if (orderTotal > parseInt(limits[1])) {
        // tslint:disable-next-line: radix
        this.util.showNeutralMessage(
          `Maximum transaction amount $${parseInt(limits[1])}.`
        );
        return;
      }
    }

    if (payment_method === 'Cash') {
      // If User is not a Guest proceed as normal
      if (!this.authService.isGuest) {
        // Present a Loading indicator to the user while the order is being processed
        this.presentLoading('Placing Order...');
        this.subs.sink = this.cartService
          .submitOrder(
            name,
            phone,
            email,
            payment_method,
            delivery_method,
            delivery_address
          )
          .subscribe(
            async (resp: ApiResponse) => {
              if (resp.code === 21) {
                this.util.showSuccessMessage(resp.message[0]);
                this.orderCode = resp.data.order.order_code;
                this.paymentMethod = resp.data.order.payment_method;
                this.orderTotal = this.total; // Get order total from cart total before we clear the cart
                this.cartService.clearCart();
                this.wasClicked = true;
                this.transactionLoading = false;
                this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                this.secIsEditable = false; // Set the editable state of the stepper to false
                this.goForward(stepper); // If transaction was successful we move the step forward
              } else {
                this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                this.util.showErrorMessage(resp.message[0]);
              }
            },
            error => {
              this.util.showServerError();
              this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
            }
          );
      } else {
        // If User is a Guest we store the details from the Contact Details form then prompt the user to create an account
        // This informaion will be used to in the account creation process
        this.guestUserService.setContactDetailsForm(form1.value);
        this.promptAccountCreation();
      }
    } else if (payment_method === 'Credit/Debit Card') {
      // If User is not a guest proceed as normal
      if (!this.authService.isGuest) {
        this.presentLoading('Placing Order...');
        // tslint:disable: radix
        // tslint:disable-next-line: max-line-length
        this.paymentService
          .makePayment(
            this.selectedPaymentCard.id,
            this.cartService.getTotal(),
            'JMD',
            this.authService.currentUser.id,
            Number.parseInt(this.cardCVV)
          )
          .subscribe(res => {
            if (res.type === 'success') {
              const paymentId = res.data.payment.id;
              this.paymentService
                .getPayment(paymentId)
                .pipe(
                  tap(r => {
                    // tslint:disable-next-line: max-line-length
                    if (
                      this.selectedPaymentCard.type !== 'keycard' &&
                      (r.data.payment.htmlData === '' ||
                        r.data.payment.status === PaymentStatus.INITIATED)
                    ) {
                      throw new Error('Payment Status: Initiated');
                    }
                  }),
                  retryWhen(errors => errors.pipe(delay(1000)))
                )
                .subscribe(async response => {
                  console.log(response);
                  if (response.data.payment.htmlData !== '') {
                    const modal = await this.modalController.create({
                      component: PaymentPage,
                      cssClass: 'payment-modal',
                      componentProps: {
                        card: response.data.payment,
                        action: 'payment',
                      },
                    });
                    modal.onDidDismiss().then(async () => {
                      this.presentLoading('Placing Order...');
                      // tslint:disable-next-line: no-shadowed-variable
                      this.paymentService
                        .getPayment(paymentId)
                        .subscribe(async response => {
                          if (response.data.payment.status !== PaymentStatus.PENDINGCAPTURE) {
                            this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                            const alert = await this.alertCtrl.create({
                              header: 'Payment Failed',
                              message: 'Please ensure your cvv was correct.',
                              buttons: [
                                {
                                  text: 'Okay',
                                  cssClass: 'card-alert-accept-btn',
                                },
                              ],
                            });
                            await alert.present();
                          } else {
                            this.cartService
                              .submitOrder(
                                name,
                                phone,
                                email,
                                payment_method,
                                delivery_method,
                                delivery_address,
                                paymentId,
                                response.data.payment.status,
                                response.data.payment.referenceNo,
                                response.data.payment.created,
                                response.data.payment.updated,
                                response.data.payment.paymentCard,
                                this.selectedPaymentCard['type']
                              )
                              .subscribe(
                                async (resp: ApiResponse) => {
                                  if (resp.code === 21) {
                                    this.util.showSuccessMessage(
                                      resp.message[0]
                                    );
                                    this.orderCode = resp.data.order.order_code;
                                    this.paymentMethod = resp.data.order.payment_method;
                                    this.orderTotal = this.total; // Get order total from cart total before we clear the cart
                                    this.buildReceipt(response, this.orderCode);
                                    this.cartService.clearCart();
                                    this.wasClicked = true;
                                    this.transactionLoading = false;
                                    this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                                    this.secIsEditable = false; // Set the editable state of the stepper to false
                                    this.goForward(stepper); // If transaction was successful we move the step forward
                                    if (!this.shouldSaveCard) {
                                      // tslint:disable-next-line: no-shadowed-variable
                                      this.paymentService
                                        .deletePaymentCard(
                                          this.selectedPaymentCard.id
                                        )
                                        .subscribe(async resp => {
                                          this.fetchPaymentCard();
                                          this.loadingCtrl.dismiss();
                                        });
                                    }
                                  } else {
                                    this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                                    this.util.showErrorMessage(resp.message[0]);
                                  }
                                },
                                error => {
                                  this.util.showServerError();
                                  this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                                }
                              );
                          }
                        });
                    });

                    this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                    await modal.present();
                  } else if (
                    this.selectedPaymentCard.type === 'keycard' &&
                    res.type === 'success'
                  ) {
                    this.loadingCtrl.dismiss();
                    this.presentLoading('Placing Order...');
                    // tslint:disable-next-line: no-shadowed-variable
                    this.paymentService
                      .getPayment(paymentId)
                      .subscribe(async response => {
                        if (response.data.payment.status !== PaymentStatus.SUCCESSFUL) {
                          this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                          const alert = await this.alertCtrl.create({
                            header: 'Payment Failed',
                            message: 'Please ensure your cvv was correct.',
                            buttons: [
                              {
                                text: 'Okay',
                                cssClass: 'card-alert-accept-btn',
                              },
                            ],
                          });
                          await alert.present();
                        } else {
                          this.cartService
                            .submitOrder(
                              name,
                              phone,
                              email,
                              payment_method,
                              delivery_method,
                              delivery_address,
                              paymentId,
                              response.data.payment.status,
                              response.data.payment.referenceNo,
                              response.data.payment.created,
                              response.data.payment.updated,
                              response.data.payment.paymentCard,
                              this.selectedPaymentCard['type']
                            )
                            .subscribe(
                              async (resp: ApiResponse) => {
                                if (resp.code === 21) {
                                  this.util.showSuccessMessage(resp.message[0]);
                                  this.orderCode = resp.data.order.order_code;
                                  this.paymentMethod =
                                    resp.data.order.payment_method;
                                  this.orderTotal = this.total; // Get order total from cart total before we clear the cart
                                  this.cartService.clearCart();
                                  this.wasClicked = true;
                                  this.buildReceipt(response, this.orderCode);
                                  this.transactionLoading = false;
                                  this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                                  this.secIsEditable = false; // Set the editable state of the stepper to false
                                  this.goForward(stepper); // If transaction was successful we move the step forward
                                  if (!this.shouldSaveCard) {
                                    // tslint:disable-next-line: no-shadowed-variable
                                    this.paymentService
                                      .deletePaymentCard(
                                        this.selectedPaymentCard.id
                                      )
                                      .subscribe(async resp => {
                                        this.fetchPaymentCard();
                                        this.loadingCtrl.dismiss();
                                      });
                                  }
                                } else {
                                  this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                                  this.util.showErrorMessage(resp.message[0]);
                                }
                              },
                              error => {
                                this.util.showServerError();
                                this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                              }
                            );
                        }
                      });
                  }
                });
            } else {
              this.util.showServerError();
              this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
            }
          });
      } else {
        // Deconstruct the Form and assign each value to the appropriate cardDetails property
        const {
          nameOnCard,
          cardNumber,
          cvv,
          expiryDate,
          cardType,
        } = form2.value;
        this.cardDetails.nameOnCard = nameOnCard;
        this.cardDetails.cardNumber = cardNumber;
        this.cardDetails.cvv = cvv;
        this.cardDetails.expiryDate = expiryDate;
        this.cardDetails.billingAddress = this.billingAddress;
        this.cardDetails.type = cardType;

        // Temporarily Stores the Contact Details Form and Card Details to Guest User Service
        this.guestUserService.setContactDetailsForm(form1.value);
        this.guestUserService.setCardDetails(this.cardDetails);
        // Prompt the User to Create an account/login
        this.promptAccountCreation();

        // Here we would handle the card payment logic
        // For now we are just logging the values to the Console
        // console.log(form1.value);
        // console.log(this.cardDetails);
      }
    } else if (payment_method === 'Voucher') {
      if (!this.authService.isGuest) {
        const amount = this.total;
        const businessPartner = this.partners.find(
          partner => partner.name === this.selectedPartner
        );
        const id = businessPartner.code + this.empId;
        const pin = this.pin;

        this.presentLoading('Placing Order...');
        this.partnerService.makeWalletPayment({ pin, id, amount }).then(res => {
          if (res.code === 21) {
            const transaction = res.data.transaction as Transaction;
            this.cartService
              .submitOrder(
                name,
                phone,
                email,
                payment_method,
                delivery_method,
                delivery_address,
                transaction.id
              )
              .subscribe(
                async (resp: ApiResponse) => {
                  if (resp.code === 21) {
                    this.util.showSuccessMessage(resp.message[0]);
                    this.orderCode = resp.data.order.order_code;
                    this.paymentMethod = resp.data.order.payment_method;
                    this.orderTotal = this.total; // Get order total from cart total before we clear the cart
                    this.cartService.clearCart();
                    this.wasClicked = true;
                    this.transactionLoading = false;
                    this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                    this.secIsEditable = false; // Set the editable state of the stepper to false
                    this.goForward(stepper); // If transaction was successful we move the step forward
                  } else {
                    this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
                    this.util.showErrorMessage(resp.message[0]);
                  }
                },
                error => {
                  this.util.showServerError();
                  this.loadingCtrl.dismiss();
                }
              );
          } else {
            this.util.showErrorMessage(res.message[0]);
            this.loadingCtrl.dismiss();
          }
        });
      } else {
        // Temporarily Stores the Contact Details Form and Card Details to Guest User Service
        this.guestUserService.setContactDetailsForm(form1.value);
        // Prompt the User to Create an account/login
        this.promptAccountCreation();
      }
    }
  }

  async promptAccountCreation() {
    const alert = await this.alertCtrl.create({
      header: 'Please Create Account!',
      message: 'Please create an account or Login to submit your order!',
      buttons: [
        {
          text: 'Create Account',
          handler: data => {
            this.goToSignUp();
          },
        },
        {
          text: 'Login',
          handler: data => {
            this.login();
          },
        },
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    return alert.present();
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Not Yet Supported!',
      message:
        'This feature is not yet supported, please choose the Cash Payment Option.',
      buttons: ['Okay'],
    });
    await alert.present();
  }

  async presentBillingAddressAlert() {
    const alert = await this.alertCtrl.create({
      header: 'No Address Found!',
      message:
        'There was no address found for this user please add a Billing Address.',
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          handler: data => {
            this.addrIsSimilar = false;
          },
        },
      ],
    });
    await alert.present();
  }

  async presentUserAddressesAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Choose address',
      inputs: this.addresses.map(address => {
        return {
          name: 'billingAddress',
          type: 'radio',
          label: address.label,
          value: address,
        } as AlertInput;
      }),
      buttons: [
        {
          text: 'Okay',
          cssClass: 'card-alert-accept-btn',
          handler: address => {
            if (address) {
              this.billingAddress.line1 = address.line1;
              this.billingAddress.line2 = address.line2;
              this.billingAddress.city = address.city;
              this.billingAddress.stateOrParish = address.parish;
              this.billingAddress.country = address.country;
              this.billingAddress.postalCode = address.zip_code;
            } else {
              this.addrIsSimilar = false;
            }
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
            if (this.billingAddress.line1 === '') {
              this.addrIsSimilar = false;
            }
          },
        },
      ],
    });
    await alert.present();
  }

  fetchAddresses() {
    if (!this.authService.isGuest) {
      this.loadingAddresses = true;
      this.newAddress = this.userService.getDeliveryAddressAdded();
      this.subs.sink = this.userService
        .getAddresses(this.authService.currentUser.id)
        .subscribe(
          resp => {
            if (resp.code === 20) {
              this.addresses = resp.data as DeliveryAddress[];
              this.firstAddress = this.addresses[0];
            } else {
              console.dir(resp);
              this.util.showSuccessMessage(resp.message[0]);
            }
            this.loadingAddresses = false;
          },
          error => {
            this.util.showErrorMessage('Could not fetch addresses');
            this.loadingAddresses = false;
          }
        );
    }
  }

  fetchTakeoutPartners() {
    this.partnerService
      .getPartners()
      .then(resp => {
        if (resp.code === 20) {
          this.partners = resp.data.business_partners as Partner[];
        }
      })
      .catch(err => {
        this.util.showErrorMessage('Could not fetch Takeout Partners.');
      });
  }

  async getNewPin() {
    if (!this.selectedPartner) {
      return this.util.showErrorMessage('You must select a company first!');
    }
    const alert = await this.alertCtrl.create({
      header: 'Request New Pin',
      message: 'To request a new pin please enter the following information.',
      cssClass: 'alert-box',
      backdropDismiss: false,
      inputs: [
        {
          name: 'email',
          type: 'text',
          placeholder: 'email',
        },
        {
          name: 'id',
          type: 'text',
          placeholder: 'id',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'card-alert-cancel-btn',
          handler: () => {
            this.addingNewCard = false;
          },
        },
        {
          text: 'Submit',
          cssClass: 'card-alert-accept-btn',
          handler: async ({ email, id }) => {
            this.presentLoading('Reseting Pin...');
            const businessPartner = this.partners.find(
              partner => partner.name === this.selectedPartner
            );
            id = businessPartner.code + id;
            const {
              code,
              data,
              message,
            } = await this.partnerService.requestNewPin({ email, id }); //TODO find company code
            if (code === 20) {
              this.util.showSuccessMessage(message[0]);
            } else {
              this.util.showErrorMessage(message[0]);
            }
            await this.loadingCtrl.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  fetchPaymentCard() {
    if (!this.authService.isGuest) {
      this.paymentService
        .getPaymentCards(this.authService.currentUser.id)
        .then(({ data }) => {
          const { paymentCards } = data;
          this.paymentCards = paymentCards;
          this.findAndConfirmNewCard();
        })
        .catch(errors => {
          this.util.showErrorMessage('Unable to fetch payment cards');
        });
    }
  }

  findAndConfirmNewCard() {
    if (this.newCardAdded) {
      const cardAdded = this.paymentCards.find(
        card => card.maskedCardNumber === this.recentlyAddedCard
      );
      this.confirmCard(cardAdded);
      this.newCardAdded = false;
    }
  }

  // Stepper Navigation Functions
  goBack(stepper: MatStepper) {
    // First we set the completed property of the step to false.
    stepper.selected.completed = false;
    // Then, set first step's editable state to true
    this.frstIsEditable = true;
    // Since this is done in constant time a Timeout is used to add a delay of 10ms
    // before we trigger the navigation action to the previous step,
    // If this was not done, we would have to click the back button a second time
    // to get the desired action.
    setTimeout(() => {
      stepper.previous();
    }, 10);
  }

  goForward(stepper: MatStepper) {
    // Here we set the editable property to false.
    this.frstIsEditable = false;
    // And the completed property of the step to true. This triggers the
    // completed icon in the mat-step header
    stepper.selected.completed = true;
    // Then we navigate to the next step.
    setTimeout(() => {
      stepper.next();
    }, 10);
  }

  dismiss() {
    this.location.back();
  }

  isGuest(): boolean {
    return this.authService.isGuest;
  }

  isAuthenticated() {
    return this.authService.authState.value;
  }

  async login() {
    const data = this.guestUserService.getContactDetailsForm();
    const modal = await this.modalCtrl.create({
      component: LoginFormPage,
      cssClass: 'login-modal',
      componentProps: {
        loginData: data,
      },
    });

    return await modal.present();
  }

  async goToSignUp() {
    const data = this.guestUserService.getContactDetailsForm();
    const modal = await this.modalCtrl.create({
      component: SignUpPage,
      cssClass: 'login-modal',
      componentProps: {
        signUpDetails: data,
      },
    });
    return await modal.present();
  }

  async presentLoading(message) {
    const loading = await this.loadingCtrl.create({
      message: message,
      translucent: true,
    });
    await loading.present();
  }

  addNewCard(form1: NgForm) {
    if (this.authService.isGuest) {
      this.guestUserService.setContactDetailsForm(form1.value);
      // Prompt the User to Create an account/login
      this.promptAccountCreation();
    } else {
      this.addingNewCard = true;
    }
  }

  getMaskedNumber(cardNumber) {
    const first4 = cardNumber.substring(0, 4);
    const last4 = cardNumber.substring(cardNumber.length - 4);
    const mask = cardNumber
      .substring(4, cardNumber.length - 4)
      .replace(/\d/g, '*');
    this.recentlyAddedCard = first4 + mask + last4;
  }

  cancelAddNewCard() {
    this.addingNewCard = false;
  }

  resetFormData(form: NgForm) {
    form.resetForm();
    this.billingAddress = {
      line1: '',
      line2: '',
      city: '',
      stateOrParish: '',
      country: '',
      postalCode: '',
    };
  }

  async addPaymentCard(form2: NgForm) {
    // Deconstruct the Form and assign each value to the appropriate cardDetails property
    const { nameOnCard, cardNumber, cvv, expiryDate } = form2.value;
    this.cardDetails.nameOnCard = nameOnCard;
    this.cardDetails.cardNumber = cardNumber.trim().split(' ').join('');
    this.cardDetails.cvv = cvv;
    this.cardDetails.expiryDate = this.formatExpiryDate(expiryDate);
    this.cardDetails.billingAddress = this.billingAddress;
    this.cardDetails.type = this.cardType;

    this.getMaskedNumber(this.cardDetails.cardNumber);

    const alert = await this.alertCtrl.create({
      header: 'Card Verification',
      // tslint:disable-next-line: max-line-length
      message:
        'To ensure this card is being used by an authorised user we will charge $0.01-$5.00 to the card which will be refunded once we have verified the account. You will be asked to enter the amount charged to complete the process.',
      cssClass: 'alert-box',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'card-alert-cancel-btn',
          handler: () => {
            this.addingNewCard = false;
          },
        },
        {
          text: 'Accept',
          cssClass: 'card-alert-accept-btn',
          handler: async () => {
            this.presentLoading('Adding card...');

            try {
              const { data } = await this.paymentService.addPaymentAddress(
                this.billingAddress
              );
              const { address } = data;
              const billingAddressId = address.id;

              if (billingAddressId) {
                // tslint:disable: max-line-length
                try {
                  const response = await this.paymentService.addPaymentCard(
                    this.cardDetails,
                    billingAddressId,
                    this.authService.currentUser.id
                  );
                  this.util.showSuccessMessage(response.message);
                  this.addingNewCard = false;
                  this.newCardAdded = true;
                  this.fetchPaymentCard();
                  this.resetFormData(form2);
                  await this.loadingCtrl.dismiss();
                } catch (error) {
                  this.util.showErrorMessage(
                    'Unable to add card, the gateway is currently down'
                  );
                  await this.loadingCtrl.dismiss();
                }
              }
            } catch (err) {
              await this.loadingCtrl.dismiss();
              this.util.showErrorMessage(err);
            }
          },
        },
      ],
    });

    await alert.present();
  }

  formatCardNumber($event) {
    // tslint:disable-next-line: radix
    if (!isNaN(Number.parseInt($event.detail.data))) {
      if (
        this.cardNumber.trim().split(' ').join('').length > 0 &&
        this.cardNumber.trim().split(' ').join('').length % 4 === 0
      ) {
        this.cardNumber += ' ' + $event.detail.data;
      }
    }
  }

  determineCardType(cardNumber: NgModel) {
    if (cardNumber.valid) {
      if (cardNumber.value[0] == '4') {
        this.cardType = 'visa';
      } else if (cardNumber.value[0] == '5') {
        this.cardType = 'mastercard';
      } else if (cardNumber.value[0] == '7') {
        this.cardType = 'keycard';
      }
      // else if (cardNumber.value[0] == '3') {
      //   this.cardType = 'american express';
      // }
    } else {
      this.cardType = '';
    }
  }

  cardSelectionDisabled(card) {
    if (card.confirmed) {
      return false;
    } else {
      return true;
    }
  }

  async confirmCard(card) {
    if (card['confirmationAttempts'] >= 3) {
      const secondaryAlert = await this.secondaryAlertCtrl.create({
        header: 'Confirmation Attempts Exceeded',
        message: 'Please remove this card and try again.',
        buttons: [{
          text: 'Remove Card',
          handler: () => {
            this.deleteCard(card);
          }
        },
        {
          text: 'Close',
          role: 'cancel',
        }]
      });
      await secondaryAlert.present();
      return;
    }

    if (!card['confirmationCharged']) {
      if (card.type.toLowerCase() !== 'keycard') {
        const modal = await this.modalController.create({
          component: PaymentPage,
          cssClass: 'payment-modal',
          componentProps: {
            card,
            action: 'confirm',
          },
        });

        modal.onDidDismiss().then(async () => {
          this.presentLoading('Loading...');
          const { data } = await this.paymentService.getPaymentCards(
            this.authService.currentUser.id
          );
          console.log(data);
          const { paymentCards } = data;
          this.paymentCards = paymentCards;
          this.loadingCtrl.dismiss();

          const alert = await this.alertCtrl.create({
            header: 'Contact Your Bank',
            message: 'Please enter the amount charged to your card.',
            cssClass: 'alert-box',
            inputs: [
              {
                name: 'amount',
                type: 'number',
                placeholder: '0.00',
                min: 0.0,
                max: 5.0,
              },
            ],
            buttons: [
              {
                text: 'Verify Card',
                cssClass: 'card-alert-accept-btn',
                handler: async data => {
                  if (
                    !/^[0-5]+\.[0-9]{2}$/g.test(
                      Number.parseFloat(data['amount']).toFixed(2)
                    )
                  ) {
                    let secondaryAlert = await this.secondaryAlertCtrl.create({
                      header: 'Card Confirmation Failed',
                      message:
                        'The value entered was invalid. Ensure it is in the format x.xx',
                      buttons: ['Okay'],
                    });
                    await secondaryAlert.present();
                    return;
                  }

                  if (Number.parseFloat(data['amount']) < 0) {
                    let secondaryAlert = await this.secondaryAlertCtrl.create({
                      header: 'Card Confirmation Failed',
                      message: 'The value entered was too small.',
                      buttons: ['Okay'],
                    });
                    await secondaryAlert.present();
                    return;
                  }

                  if (Number.parseFloat(data['amount']) > 5) {
                    let secondaryAlert = await this.secondaryAlertCtrl.create({
                      header: 'Card Confirmation Failed',
                      message: 'The value entered was too large.',
                      buttons: ['Okay'],
                    });
                    await secondaryAlert.present();
                    return;
                  }
                  this.presentLoading('Confirming card...');
                  try {
                    const resp = await this.paymentService.confirmCard(card, Number.parseFloat(data['amount']));
                    if (resp.code !== 20) {
                      await this.loadingCtrl.dismiss();
                      const failedAlert = await this.secondaryAlertCtrl.create({
                        message: 'Confirmation Failed. Please ensure you entered the correct amount that was charged.',
                        translucent: true,
                      });
                      await failedAlert.present();
                    } else {
                      this.fetchPaymentCard();
                      this.loadingCtrl.dismiss();
                      this.util.showSuccessMessage(resp.message[0]);
                    }
                  } catch (err) {
                    await this.loadingCtrl.dismiss();
                    const failedAlert = await this.secondaryAlertCtrl.create({
                      message: 'Confirmation Failed. Please ensure you entered the correct amount that was charged.',
                      translucent: true,
                    });
                    await failedAlert.present();
                  }
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
              },
            ],
          });
          await alert.present();
        });

        return modal.present();
      } else {
        const secondaryAlert = await this.secondaryAlertCtrl.create({
          message: 'Checking if card has been confirmed',
          buttons: [
            {
              text: 'Okay',
              cssClass: 'card-alert-accept-btn',
              handler: () => {
                this.fetchPaymentCard();
              },
            },
          ],
        });
        await secondaryAlert.present();
      }
    } else if (card['confirmationAttempts'] >= 3) {
      let secondaryAlert = await this.secondaryAlertCtrl.create({
        header: 'Card Confirmation Attempts Exceeded',
        message: 'Please remove and add this card again.',
        buttons: ['Okay'],
      });
      await secondaryAlert.present();
      return;
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Contact Your Bank',
        message:
          'Please enter the amount (between 0.00 and 5.00) charged to your card.',
        cssClass: 'alert-box',
        inputs: [
          {
            name: 'amount',
            type: 'number',
            placeholder: '0.00',
            min: 0.0,
            max: 5.0,
          },
        ],
        buttons: [
          {
            text: 'Verify Card',
            cssClass: 'card-alert-accept-btn',
            handler: async data => {
              if (
                !/[0-5]+\.[0-9]{2}/g.test(
                  Number.parseFloat(data['amount']).toFixed(2)
                )
              ) {
                let secondaryAlert = await this.secondaryAlertCtrl.create({
                  header: 'Card Confirmation Failed',
                  message:
                    'The value entered was invalid. Ensure it is in the format x.xx',
                  buttons: ['Okay'],
                });
                await secondaryAlert.present();
                return;
              }

              if (Number.parseFloat(data['amount']) < 0) {
                let secondaryAlert = await this.secondaryAlertCtrl.create({
                  header: 'Card Confirmation Failed',
                  message: 'The value entered was too small.',
                  buttons: ['Okay'],
                });
                await secondaryAlert.present();
                return;
              }

              if (Number.parseFloat(data['amount']) > 5) {
                let secondaryAlert = await this.secondaryAlertCtrl.create({
                  header: 'Card Confirmation Failed',
                  message: 'The value entered was too large.',
                  buttons: ['Okay'],
                });
                await secondaryAlert.present();
                return;
              }
              this.presentLoading('Confirming card...');

              try {
                const resp = await this.paymentService.confirmCard(card, Number.parseFloat(data['amount']));
                if (resp.code !== 20) {
                  await this.loadingCtrl.dismiss();
                  const failedAlert = await this.secondaryAlertCtrl.create({
                    message: 'Confirmation Failed. Please ensure you entered the correct amount that was charged.',
                    translucent: true,
                  });
                  await failedAlert.present();
                } else {
                  this.fetchPaymentCard();
                  this.loadingCtrl.dismiss();
                  this.util.showSuccessMessage(resp.message[0]);
                }
              } catch (err) {
                await this.loadingCtrl.dismiss();
                const failedAlert = await this.secondaryAlertCtrl.create({
                  message: 'Confirmation Failed. Please ensure you entered the correct amount that was charged.',
                  translucent: true,
                });
                await failedAlert.present();
              }
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
          },
        ],
      });
      await alert.present();
    }
  }

  async deleteCard(card) {
    const cardDeletedAlert = await this.alertCtrl.create({
      message: 'Are you sure you want to remove this card?',
      buttons: [
        {
          text: 'Remove',
          cssClass: 'card-alert-accept-btn',
          handler: () => {
            this.presentLoading('Deleting card...');
            this.paymentService
              .deletePaymentCard(card['id'])
              .subscribe(async resp => {
                this.fetchPaymentCard();
                this.loadingCtrl.dismiss();
                const cardDeletedAlert = await this.alertCtrl.create({
                  message: resp.message[0],
                  buttons: ['Okay'],
                });

                return await cardDeletedAlert.present();
              });
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });

    return cardDeletedAlert.present();
  }

  buildReceipt(response, orderCode) {
    const {paymentCard, amount, authCode, created, currency, referenceNo} = response.data.payment;
    const purchaserName = this.authService.currentUser.firstname + ' ' + this.authService.currentUser.lastname;

    this.receipt = new Receipt(
      this.safeMaskedNumber(paymentCard),
      referenceNo,
      'https://takeout.yaadiemarket.com/',
      purchaserName,
      new Date().toDateString(),
      new Intl.NumberFormat('en-us').format(amount),
      currency,
      authCode,
      'Takeout',
      'Speur - Ecom',
      orderCode
    );
  }

  generateReceipt() {
    const receiptDoc = new jsPDF();

    receiptDoc.addImage('../../assets/takeout.jpg', 'JPEG', 40, 10, 80, 17);
    receiptDoc.text(`Order Number: ${this.receipt.orderNumber}`, 40, 80);
    receiptDoc.text(`Transaction Number: ${this.receipt.uniqueId}`, 40, 90);
    receiptDoc.text(`Purchaser Name: ${this.receipt.purchaserName}`, 40, 100);
    receiptDoc.text(`Card Number: ${this.receipt.cardNumber}`, 40, 110);
    receiptDoc.text(`Transaction Date: ${this.receipt.transactionDate}`, 40, 120);
    receiptDoc.text(`Transaction Amount: $${this.receipt.transactionAmount} ${this.receipt.currency}`, 40, 130);
    receiptDoc.text(`Authorization Code:  ${this.receipt.authorizationCode}`, 40, 140);
    receiptDoc.text(`Merhant Name: ${this.receipt.merchantName}`, 40, 150);
    receiptDoc.text(`Website Address: ${this.receipt.websiteAddress}`, 40, 160);
    receiptDoc.text(`Charged By: ${this.receipt.chargedBy}`, 40, 170);
    receiptDoc.addImage('../../assets/powered_by.jpg', 'JPEG', 40, 220, 60, 8);

    receiptDoc.save(`takeout_${this.receipt.orderNumber}_receipt.pdf`);
  }

  logout() {
    this.authService.logout();
    this.cartService.clearCart();
    this.router.navigateByUrl('/home');
  }

  openCart() {
    this.router.navigateByUrl(`cart`);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
