import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BillingAddress, PaymentCard } from 'src/app/models/user.model';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/takeout.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private url = environment.APIEndpoint;

  constructor(private http: HttpClient, private auth: AuthService) { }

  public getPaymentCards(userId): Promise<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + `/payment/card/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + this.auth.token
      }
    }).toPromise();
  }

  public addPaymentAddress(address: BillingAddress): Promise<ApiResponse>{
    const {line1, line2, stateOrParish, city, country, postalCode}  =  address;
    // console.log(this.url + `payment/billing_address`);
    return this.http.post<ApiResponse>(this.url + `/payment/billing_address`, {
      // tslint:disable-next-line: max-line-length
      line1, line2, state: stateOrParish, city, country, zipCode: postalCode, user: this.auth.currentUser.id
    }, {
      headers: {
        Authorization: 'Bearer ' + this.auth.token
      }
    }).toPromise();
  }


  public addPaymentCard(card: PaymentCard, billingAddress, user): Promise<ApiResponse> {
    const {expiryDate, cvv, cardNumber, nameOnCard, type, enabled} = card;
    return this.http.post<ApiResponse>(this.url + `/payment/card`, {
      expiryDate, cvv, nameOnCard, billingAddress, type, cardNumber, enabled, user
    }, {
      headers: {
        Authorization: 'Bearer ' + this.auth.token
      }
    }).toPromise();
  }

  public deletePaymentCard(id: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.url + `/payment/delete_card/${id}`, {
      headers: {
        Authorization: 'Bearer ' + this.auth.token
      }
    });
  }

  public getBillingAddresses(userId): Promise<ApiResponse>{
    return this.http.get<ApiResponse>(this.url + `/payment/billing_address/${userId}`, {
      headers:{
        Authorization:"Bearer "+ this.auth.token
      }
    }).toPromise();
  }

  public confirmCard(card, amount) : Promise<ApiResponse> {
    return this.http.post<ApiResponse>(this.url + `/payment/confirm_card`,{
      "paymentCard":card['id'], amount, "user": card['user']
    }, {
      headers:{
        Authorization:"Bearer "+ this.auth.token
      }
    }).toPromise();
  }

  public makePayment(cardId, amount, currency, user, cvv ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.url + `/payment/make_payment`, {
      "paymentCard":cardId, amount, cvv, currency, user, autoCapture:false
    }, {
      headers:{
        Authorization: "Bearer "+this.auth.token
      }
    });
  }

  public getPayment(paymentId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + `/payment/get_payment/${paymentId}`, {
      headers:{
        Authorization: "Bearer "+this.auth.token
      }
    });
  }

  public getPayments(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + `/payment/get_payments/${this.auth.currentUser.id}`, {
      headers:{
        Authorization: "Bearer "+this.auth.token
      }
    });
  }
}
