import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private toastCtrl: ToastController
  ) { }

  /**
   * Displays a toast to the user.
   */
  async showSuccessMessage(msg: string, heading: string = '', duration: number = 3000) {
    const toast = await this.toastCtrl.create({
      message: msg,
      header: heading,
      duration,
      position: 'top',
      color: 'success',
      cssClass: 'toast-alert',
      buttons: [
        {
          text: 'dismiss',
          icon: 'ios-close',
          side: 'end',
          handler: () => {
            toast.dismiss();
          },
        },
      ],
    });

    toast.present();
  }

  async showNeutralMessage(msg: string, heading: string = '', duration: number = 3000) {
    const toast = await this.toastCtrl.create({
      message: msg,
      header: heading,
      duration,
      position: 'top',
      color: 'dark',
      cssClass: 'toast-alert',
      buttons: [
        {
          text: 'dismiss',
          icon: 'ios-close',
          side: 'end',
          handler: () => {
            toast.dismiss();
          },
        },
      ],
    });

    toast.present();
  }


  async showErrorMessage(msg: string, heading: string = '', duration: number = 5000) {
    const toast = await this.toastCtrl.create({
      message: msg,
      header: heading,
      duration,
      position: 'top',
      color: 'danger',
      cssClass: 'toast-alert',
      buttons: [
        {
          text: 'dismiss',
          icon: 'ios-close',
          side: 'end',
          handler: () => {
            toast.dismiss();
          },
        },
      ],
    });

    toast.present();
  }

  showServerError() {
    this.showErrorMessage('We cannot process your request', 'Server Down', 5000);
  }
}
